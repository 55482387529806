import properties from 'resources/constants/properties.json';

export default (() => {

  const setApiUrl = apiUrl => {
    localStorage.setItem("ng-api-url", apiUrl);
  }

  const getApiUrl = () => {
    const apiUrl = localStorage.getItem("ng-api-url");
    if(apiUrl === "undefined" || apiUrl === "null" || apiUrl === null) {
      return properties.baseUrl.api
    }
    return apiUrl;
  }

  return {
    setApiUrl,
    getApiUrl
  }
})()