import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { initialState, officeApp } from 'reducers';
import FORMULAS from 'resources/constants/Formulas.js';
import properties from 'resources/constants/properties.json';
import { AuthUtils, ExcelUtils, FileUtils, GAUtils, Parameters, TimeSeriesUtils } from 'utils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace

if(!window["ngStore" + FUNCTIONS_NAMESPACE]) {
  window["ngStore" + FUNCTIONS_NAMESPACE] = createStore((state, action) => officeApp(state, action), initialState, applyMiddleware(thunkMiddleware))
}

// /**
//  * Gets the content of the latest result of Data Prep execution and inserts it as the table beneath the formula field.
//  * @customfunction GET.DATAPREP GET.DATAPREP
//  * @requiresAddress
//  * @param {string} dataprepName Name of the Data Prep
//  * @param {string[]} [parameters] Optional parameters.
//  * @param {CustomFunctions.Invocation} invocation Invocation object.
//  * @return {string} Name of the Data Prep and table beneath.
//  */

// export async function dataprepLoad(dataprepName, parameters, invocation) {
//   try {
//     const parsedParameters = new Parameters.Builder()
//     .withRequiredParameter("dataprepName", dataprepName)
//     .withUserParameters(parameters)
//     .build();
//     await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
//     GAUtils.sendEvent({
//       category: "view_item",
//       action: FORMULAS.LOAD_DATAPREP,
//       label: "function"
//     })
//     const address = invocation.address
//     const searchResponse = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(DataprepUtils.searchDataPrepsCore({ input: `name.keyword=${parsedParameters.dataprepName}`, size: 1, noFething: false }))
//     if(!searchResponse.items?.[0]) {
//       throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "Could not find the data prep.")
//     }
//     const fid = searchResponse.items[0].generatedFileId
//     if(!fid) {
//       throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "Data prep was not executed yet.")
//     }
//     const response = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(FileUtils.readFile({ fileId: fid }))
//     await ExcelUtils.insertTable({ values: response, address, tableName: DataprepUtils.DP_PREFIX + parsedParameters.dataprepName })
//     return parsedParameters.dataprepName
//   } catch(e) {
//     console.error(e)
//     throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
//   }
// }

/**
 * Gets the content of the latest file with the given name and given group from Datalake. Inserts it as the table beneath the formula field.
 * @customfunction GET.FILE GET.FILE
 * @requiresAddress
 * @param {string} fileName Name of the Data Lake file that will be downloaded. Exact match.
 * @param {string} groupName Name of the Data Lake group, in which the file is.
 * @param {string[]} [parameters] Optional parameters
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return {string} Name of the file and table beneath.
 */

export async function fileLoad(fileName, groupName, parameters, invocation) {
  try {
    const parsedParameters = new Parameters.Builder()
      .withRequiredParameter("fileName", fileName)
      .withRequiredParameter("groupName", groupName)
      .withUserParameters(parameters)
      .build();
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.LOAD_DATAPREP,
      label: "function"
    })
    const address = invocation.address
    const searchResponse = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(FileUtils.searchFilesCore({ query: `name.keyword=${parsedParameters.fileName}&groupName=${parsedParameters.groupName}&latest=true`, size: 1, noFetching: false }))
    const fid = searchResponse.items?.[0]?.fid
    if(!fid) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "File not found!")
    }
    const response = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(FileUtils.readFile({ fileId: fid }))
    await ExcelUtils.insertTable({ values: response, address, tableName: FileUtils.FILE_PREFIX + parsedParameters.fileName + FileUtils.GROUP_PREFIX + parsedParameters.groupName })
    return parsedParameters.fileName
  } catch(e) {
    console.error(e)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}

/**
 * Gets the latest data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction GET.TS GET.TS
 * @requiresAddress
 * @param {string} groupName Name of the Data Lake group, in which the data is.
 * @param {string} values values that should be returned
 * @param {string[]} parameters Symbols and optional parameters
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return {string} Name
 */

export async function getTimeSeries(groupName, values, parameters, invocation) {
  try {
    const parsedParameters = new Parameters.Builder()
    .withRequiredParameter("groupName", groupName)
    .withRequiredArrayParameter("values", values)
    .withUserParameters(parameters)
    .withSplitUserParametersFunction(({ key }) => !TimeSeriesUtils.SUPPORTED_TS_PARAMS.includes(key))
    .build();
    if(Object.keys(parsedParameters.getSplittedParameters()).length === 0) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "Symbol can not be empty!")
    }
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.LOAD_TS,
      label: "function"
    })
    const address = invocation.address
    const dataResponse = await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(await TimeSeriesUtils.getData({
      ...parsedParameters,
      symbol: parsedParameters.getSplittedParameters(),
    }))
    if(!dataResponse) {
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, "No data returned in the response!")
    }
    await ExcelUtils.insertTable({ values: dataResponse, address, tableName: TimeSeriesUtils.TS_GROUP_PREFIX + parsedParameters.groupName + TimeSeriesUtils.TS_COLUMNS_PREFIX + parsedParameters.values.join(",") + TimeSeriesUtils.TS_SYMBOLS_PREFIX + JSON.stringify(parsedParameters.getSplittedParameters() || {}) })
    return groupName;
  } catch (e) {
    console.error(e);
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}

/**
 * Gets the latest data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction REFRESH REFRESH
 * @param {string} [address] Address of cell or sheet that should be refreshed. Active sheet if left empty.
 * @return {string} Done
 */
export async function refresh(address) {
  try {
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.REFRESH,
      label: "function"
    })
    if(address) {
      if(String(address).split("!")?.length > 1) {
        await Excel.run(async context => {
          const worksheet = context.workbook.worksheets.getItem(address.split("!")[0]),
                range = worksheet.getRange(address.split("!")[1])
          range.calculate();
          await context.sync();
        })
      } else {
        await Excel.run(async context => {
          const worksheet = context.workbook.worksheets.getItem(address)
          await ExcelUtils.refreshAllFormulasInSheet({ worksheet, context })
        });
      }
    } else {
      await Excel.run(async context => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        await ExcelUtils.refreshAllFormulasInSheet({ worksheet: activeWorksheet, context })
      });
    }
    return "Done";
  } catch (e) {
    console.error(e);
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}


/**
 * Gets the latest data for the given symbols pattern from given group. Inserts it as the table beneath the formula field.
 * @customfunction REFRESH.ALL REFRESH.ALL
 * @return {string} Done
 */
export async function refreshAll() {
  try {
    await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(AuthUtils.validateToken())
    GAUtils.sendEvent({
      category: "view_item",
      action: FORMULAS.REFRESH_ALL,
      label: "function"
    })
    await Excel.run(async context => {
      await ExcelUtils.refreshAllFormulas({ context })
    });
    return "Done";
  } catch (e) {
    console.error(e);
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, e.message)
  }
}
CustomFunctions.associate("GET.FILE", fileLoad);
CustomFunctions.associate("GET.TS", getTimeSeries);
CustomFunctions.associate("REFRESH", refresh);
CustomFunctions.associate("REFRESH.ALL", refreshAll);